import { FetchSignature } from './FetchSignature';

declare global {
    export type WithPumpAuthenticityValidationSignature = () => IFetch;

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace fetch {
        export let withPumpAuthenticityValidation: WithPumpAuthenticityValidationSignature;
    }

    export interface IFetch extends FetchSignature {
        withPumpAuthenticityValidation: WithPumpAuthenticityValidationSignature;
    }
}

export function validateIsPumpAuthenticResponse(response: Response): void {
    const expectedHeaderName = 'response-source';
    const expectedHeaderValue = 'PUMP';

    if (!response.headers.has(expectedHeaderName)) {
        throw new Error('Inauthentic PUMP response: expected header missing');
    }

    if (response.headers.get(expectedHeaderName) != expectedHeaderValue) {
        throw new Error('Inauthentic PUMP response: expected header has unexpected value');
    }
}

fetch.withPumpAuthenticityValidation = function(): IFetch {
    const wrapped = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        const inner = (): Promise<Response> => fetch(input, init);
        const response = await inner();
        validateIsPumpAuthenticResponse(response);
        return response;
    };
    return Object.assign(wrapped, fetch);
};
